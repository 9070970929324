<template>
  <v-container fluid fill-height class="loginOverlay">
        <v-layout flex align-center justify-center>
            <v-col
                cols="12"
                xm="12"
                sm="12"
                md="6"
                lg="4"
                xl="2"
            >
                <v-card
                    class="w-100"
                    outlined
                >
                    <v-col cols="12">
                        <v-row
                            justify="center"
                        >
                            <v-card-title>
                                Login
                            </v-card-title>
                        </v-row>
                        <v-row
                            v-if="error"
                            justify="center"
                        >
                            <small class="red--text">Email or password incorrect</small>
                        </v-row>
                    </v-col>
                    <v-form v-model="valid" ref="form">
                        <v-col cols="12">
                            <v-text-field
                                label="Enter your e-mail address"
                                v-model="loginInfo.email"
                                :rules="emailRules"
                                placeholder=""
                                required
                                id="inputemail"
                            ></v-text-field>
                            <v-text-field
                                label="Enter your password"
                                v-model="loginInfo.password"
                                min="8"
                                :append-icon="e1 ? 'visibility' : 'visibility_off'"
                                @click:append="() => (e1 = !e1)"
                                @mouseup:append="()=>(e1 = !e1)"
                                :type="e1 ? 'password' : 'text'"
                                :rules="passwordRules"
                                counter
                                required
                                id="inputpassword"
                            ></v-text-field>
                            <v-row
                                justify="center"
                                class="pt-4"
                            >
                                <v-btn x-large @click="submit">Login!</v-btn>
                            </v-row>
                            <v-row
                                justify="center"
                                class="pt-4"
                            >
                                <v-btn link :to="{name: 'register'}">Register</v-btn>
                            </v-row>

                            <v-row
                                justify="center"
                                class="pt-4"
                            >
                                <a href="#">Forgot Password</a>
                            </v-row>
                        </v-col>
                    </v-form>
                </v-card>
            </v-col>
        </v-layout>
    </v-container>
</template>
<script>
import { mapActions } from 'vuex'
export default {
  data: () => ({
    valid: false,
    e1: true,
    error: false,
    loginInfo: {
      email: '',
      password: ''
    },
    passwordRules: [
      (v) => !!v || 'Password is required',
      (v) => v.length > 7
    ],
    emailRules: [
      (v) => !!v || 'E-mail is required',
      // eslint-disable-next-line
      (v) => /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'
    ]
  }),
  methods: {
    submit () {
      if (this.$refs.form.validate()) this.loginUser()
    },
    ...mapActions(['login']),
    loginUser: function () {
      this.error = false
      this.login(this.loginInfo)
        .then(() => {
          const searchParams = new URLSearchParams(window.location.search)
          if (searchParams.get('redirect')) {
            this.$router.push(searchParams.get('redirect'))
          } else {
            this.$router.push({ name: 'home' })
          }
        })
        .catch((error) => {
          this.error = true
          throw error
        })
    }
  }
}
</script>
<style>
input:-webkit-autofill{
  color:inherit;
  background: inherit;
}
</style>
